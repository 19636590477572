<template>
    <b-sidebar id="add-new-diagnostic-image-category-sidebar" :visible="isAddNewDiagnosticImageCategorySidebarActive"
        bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-diagnostic-image-category-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">{{ $t("Add") }} {{ $t('New') }} Package</h5>

                <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group :label="$t('Form.Name')" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Type package name" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Price -->
                    <validation-provider #default="validationContext" name="price" rules="required">
                        <b-form-group :label="$t('Price')" label-for="price">
                            <b-form-input id="price" v-model="stateData.price" autofocus type="number"
                                :state="getValidationState(validationContext)" trim placeholder="Type package price" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Price -->
                    <validation-provider #default="validationContext" name="price cost" rules="required">
                        <b-form-group :label="$t('Price Cost')" label-for="price">
                            <b-form-input id="price" v-model="stateData.price_cost" autofocus type="number"
                                :state="getValidationState(validationContext)" trim placeholder="Type package price" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Diagnostic imagings -->
                    <b-form-group label="Diagnostic imagings" label-for="Diagnostic imagings">
                        <treeselect id="status" v-model="stateData.diagnostic_imagings" multiple
                            :options="optionsDiagnostic" :normalizer="normalizer" />
                    </b-form-group>

                    <!-- Laboratories -->
                    <b-form-group label="Laboratories" label-for="Laboratories">
                        <treeselect id="status" v-model="stateData.laboratories" multiple :options="optionsLaboratories"
                            :normalizer="normalizer" />
                    </b-form-group>

                    <!-- Procedures -->
                    <b-form-group label="Procedures" label-for="Procedures">
                        <treeselect id="status" v-model="stateData.procedures" multiple :options="optionsProcedures"
                            :normalizer="normalizer" />
                    </b-form-group>

                    <!-- Test -->
                    <b-form-group label="Test" label-for="Test">
                        <treeselect id="status" v-model="stateData.tests" multiple :options="optionsTest"
                            :normalizer="normalizer" />
                    </b-form-group>

                    <!-- Treatments -->
                    <b-form-group label="Treatments" label-for="Treatments">
                        <treeselect id="status" v-model="stateData.treatments" multiple :options="optionsTreatments"
                            :normalizer="normalizer" />
                    </b-form-group>

                    <div v-for="(treat, index) in stateData.treatments" :key="index">
                        <b-form-group :label="`Quantity of ${getTreatment(treat)}`" label-for="Treatments">
                            <b-form-input v-model="stateData.quantity[treat]" type="number" />
                        </b-form-group>
                    </div>

                    <!-- status -->
                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group :label="$t('Form.Status')" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">{{ $t("Add") }}</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
            <ToastNotification ref="toastRef" />
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import ToastNotification from "@/components/ToastNotification";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosAC from "@/core/services/api/admin/consults/packages";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,
        ToastNotification,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isAddNewDiagnosticImageCategorySidebarActive",
        event: "update:is-add-new-diagnostic-image-category-sidebar-active",
    },
    props: {
        isAddNewDiagnosticImageCategorySidebarActive: {
            type: Boolean,
            required: true,
        },
        optionsDiagnostic: {
            type: Array,
            required: true
        },
        optionsLaboratories: {
            type: Array,
            required: true
        },
        optionsProcedures: {
            type: Array,
            required: true
        },
        optionsTest: {
            type: Array,
            required: true
        },
        optionsTreatments: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
    setup(props, { emit }) {
        const optionsStatus = [
            { name: "Active", id: true },
            { name: "Inactive", id: false },
        ];
        const getTreatment = (treatment) => {
            const trt = props.optionsTreatments.find(tr => tr.id == treatment)
            return trt ? trt.name : ""
        }
        const stateDataTem = {
            name: "",
            status: true,
            quantity: []
        };
        const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

        const resetuserData = () => {
            stateData.value = JSON.parse(JSON.stringify(stateDataTem));
        };
        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetuserData);

        const loading = ref(false);
        const toastRef = ref(null)
        const onSubmit = () => {
            loading.value = true;
            const newTreatments = []
            stateData.value.quantity.forEach((quantity, indx) => {
                if (!quantity || quantity <= 0) {
                    loading.value = false;
                    return toastRef.value.danger("Check all the treatments!");
                }
                if (stateData.value.treatments.find(tr => tr == indx)) newTreatments.push({ id: indx, cant: quantity })
            })
            if (newTreatments.length != stateData.value.treatments.length) {
                loading.value = false;
                return toastRef.value.danger("Check all the treatments!");
            }
            stateData.value.treatments = newTreatments
            const datos = {
                ...stateData.value
            };
            axiosAC.createPackage(datos).then(({ registro }) => {
                loading.value = false;
                resetuserData();
                emit("createDiagnosticImageCategory", { status: true, registro });
            })
                .catch(() => {
                    loading.value = false;
                    emit("createDiagnosticImageCategory", { status: false });
                });
        };
        return {
            loading,
            stateData,
            optionsStatus,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            getTreatment,
            toastRef
        };
    },
};
</script>

<style></style>
